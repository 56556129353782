<template>
  <q-page class="q-py-md q-px-xl">
    <div class="row q-gutter-xl">
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Data Terhimpun</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">{{ count_survey }}</q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Perlu Karantina</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">{{ need_karantina }}</q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Perlu Isolasi</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">{{ need_isolasi }}</q-card-section>
        <q-card-section class="q-py-xs text-right">Kamar</q-card-section>
      </q-card>
    </div>

    <div class="full-width">
      <q-card class="full-width q-my-md">
        <q-card-section horizontal class="row justify-between items-end">
          <q-card-section>
            <q-select
              filled
              emit-value
              map-options
              dense
              v-model="selectPeriod"
              :options="optionPeriod"
              label="Periode Arsip"
              style="min-width:300px"
              @input="fetchData(orderBy)"
            />
          </q-card-section>

          <q-card-section>
            <q-btn
              outline
              no-caps
              :disabled="!selectPeriod"
              color="indigo-10"
              label="Download Arsip"
              @click="downloadArsip"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
      <q-card flat bordered>
        <q-card-section class="row justify-between items-end">
          <div v-if="range_date.from" class="text-grey">
            Periode : {{ range_date.from | moment("DD MMMM YYYY") }} s.d
            {{ range_date.to | moment("DD MMMM YYYY") }}
          </div>
          <div v-else class="text-grey text-italic">-- s.d. --</div>

          <div class="row q-gutter-md items-end">
            <q-select
              label="Filter by Divisi:"
              filled
              dense
              map-options
              v-model="seldiv"
              use-input
              hide-selected
              fill-input
              input-debounce="0"
              :options="filtereddiv"
              style="max-width:150px"
              @input="fetchData('')"
              @filter="filterFn"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">No results</q-item-section>
                </q-item>
              </template>
            </q-select>
            <q-input
              filled
              v-model="search"
              placeholder="Cari Nama"
              dense
              style="border-radius: 5px; width: 350px;"
              @keypress.enter="fetchData('nama')"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  icon="search"
                  class="text-white absolute-right"
                  @click="fetchData('nama')"
                  style="background-color:#03913F"
                />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr>
              <th style="width:10px;" @click="fetchData('no_induk')">No Induk</th>
              <th class="text-left" @click="fetchData('nama')">Nama</th>
              <th class="text-left" @click="fetchData('')">Jabatan</th>
              <th class="text-left" @click="fetchData('')">Divisi</th>
              <th class="text-center" @click="fetchData('jenis_kelamin')">Gender</th>
              <th class="text-center" @click="fetchData('no_kamar')">kamar</th>
              <th class="text-right" @click="fetchData('nilai_survey')">Nilai Survey</th>
            </tr>
          </thead>
          <tbody v-if="listData.length" class>
            <tr v-for="d in listData" :key="d.spp_id">
              <td class="text-left">{{ d.no_induk }}</td>
              <td class="text-left">{{ d.nama }}</td>
              <td class="text-left">{{ d.jabatan }}</td>
              <td class="text-left">{{ d.divisi }}</td>
              <td class="text-center" style="width: 100px;">{{ d.jenis_kelamin }}</td>
              <td class="text-center" style="width: 100px;">{{ d.no_kamar }}</td>
              <td
                :class="
                  'text-center ' + (d.karantina == 'Y' ? 'text-negative ' : '')
                "
                style="width: 100px;"
              >{{ d.nilai_survey }}</td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">tidak ada data</td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-card-actions align="right">
          <q-pagination
            v-model="activePage"
            :max="Math.ceil(count_page / 10)"
            input
            input-class="text-orange-10"
            @click="fetchData(orderBy)"
          />
        </q-card-actions>
      </q-card>
    </div>
  </q-page>
</template>

<style></style>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "Result",
  data() {
    return {
      seldiv: "Semua Divisi",
      filtereddiv: [],
      selectPeriod: null,
      optionPeriod: [],
      listData: [],
      listData_all: [],
      need_karantina: 0,
      need_isolasi: 0,
      count_siswa: 0,
      count_survey: 0,
      count_page: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      range_date: {},
      kode_unik: "ARRCVD",
      search: "",
      confirm: false,

      excelColumns: [
        {
          label: "Tanggal",
          field: "tanggal",
          dataFormat: this.dateFormat
        },
        {
          label: "No Induk",
          field: "no_induk"
        },
        {
          label: "Nama",
          field: "nama"
        },
        {
          label: "Jenis Kelamin",
          field: "jenis_kelamin"
        },
        {
          label: "Jabatan",
          field: "jabatan"
        },
        {
          label: "Gedung",
          field: "gedung"
        },
        {
          label: "Kamar",
          field: "no_kamar"
        },
        {
          label: "Nilai Survey",
          field: "nilai_survey"
        },

        {
          label: "Q1",
          field: "Q1"
        },
        {
          label: "Q2",
          field: "Q2"
        },
        {
          label: "Q3",
          field: "Q3"
        },
        {
          label: "Q4",
          field: "Q4"
        },
        {
          label: "Q5",
          field: "Q5"
        },
        {
          label: "Q6",
          field: "Q6"
        },
        {
          label: "Q7",
          field: "Q7"
        },
        {
          label: "Q8",
          field: "Q8"
        },

        {
          label: "Karantina",
          field: "karantina"
        },
        {
          label: "Surveyor",
          field: "surveyor"
        }
      ]
    };
  },
  mounted() {
    this.fetchData("no_induk");
  },
  methods: {
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.filtereddiv = this.$DIVISI.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    dateFormat(val) {
      return this.$moment(val).format("DD-MMM-YYYY HH:MM:SS");
    },
    async downloadArsip() {
      this.seldiv = "Semua Divisi";
      await this.fetchData();

      let columns = this.excelColumns;
      let divisi = this.$DIVISI;

      let dataGuru = this.listData_all.filter(
        x => x.jabatan == "GURU" || x.jabatan == "KARYAWAN"
      );
      let dataSiswa = this.listData_all.filter(x => x.jabatan == "SISWA");

      let siswaALL = [];
      let guruALL = [];

      //get data siswa per divisi
      siswaALL.push(dataSiswa.filter(x => x.divisi == "SMP PA"));
      siswaALL.push(dataSiswa.filter(x => x.divisi == "SMP PI"));
      siswaALL.push(dataSiswa.filter(x => x.divisi == "MA"));

      for (let div of divisi) {
        guruALL.push(dataGuru.filter(x => x.divisi == div));
      }

      let filename = "Arsip Survey COVID " + this.selectPeriod;
      let sheetname = "Hasil Survey";

      //OBJ siswa
      let createXLSLFormatObjALL = [];

      let newXlsHeader = [];
      // let vm = this;
      if (columns.length === 0) {
        return;
      }
      // if (data.length === 0) {
      //   return;
      // }

      columns.forEach((value, index) => {
        newXlsHeader.push(value.label);
      });

      for (let i in siswaALL) {
        let tempOBJ = [];
        siswaALL[i].forEach((value, index) => {
          let innerRowData = [];
          columns.forEach((val, index) => {
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(value[val.field]));
            } else {
              innerRowData.push(value[val.field]);
            }
          });
          tempOBJ.push(innerRowData);
        });
        tempOBJ.unshift(newXlsHeader);
        createXLSLFormatObjALL.push(tempOBJ);
      }

      for (let i in guruALL) {
        let tempOBJ = [];
        guruALL[i].forEach((value, index) => {
          let innerRowData = [];
          columns.forEach((val, index) => {
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(value[val.field]));
            } else {
              innerRowData.push(value[val.field]);
            }
          });
          tempOBJ.push(innerRowData);
        });
        tempOBJ.unshift(newXlsHeader);
        createXLSLFormatObjALL.push(tempOBJ);
      }

      let createXLSLSummaryObj = [];
      createXLSLSummaryObj.push(["Data Terhimpun : ", this.count_survey]);
      createXLSLSummaryObj.push(["Perlu Karantina : ", this.need_karantina]);
      createXLSLSummaryObj.push(["Perlu Isolasi : ", this.need_isolasi]);
      createXLSLSummaryObj.push([
        "Periode : ",
        this.$moment(this.range_date.from).format("DD-MMM-YYYY") +
          " hingga " +
          this.$moment(this.range_date.to).format("DD-MMM-YYYY")
      ]);

      let qGuru = [
        {
          no: "1.",
          text:
            "Pernah berkunjung ke tempat umum (pasar, fasyankes, kerumunan, dll) dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "2.",
          text:
            "Pernah menggunakan transportasi umum dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "3.",
          text:
            "Pernah melakukan perjalanan luar kota/internasional(zona merah) dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "4.",
          text:
            "Pernah mengikuti kegiatan yang melibatkan orang banyak dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "5.",
          text:
            "Pernah kontak erat dengan ODP, PDP atau konfirm COVID-19(jabat tangan, berbicara, berada dalam 1 ruangan) dalam 7 hari terakhir? (50 poin)"
        },
        {
          no: "6.",
          text:
            "Pernah mengalami minimal salah satu dari demam, batuk, pilek, sakit tenggorokan, sesak, hilang penciuman, hilang perasa dalam 7 hari terakhir? (50 poin)"
        },
        {
          no: "7.",
          text:
            "Ada orang serumah yang berinteraksi dengan ODP, PDP atau konfirm COVID-19 dalam 7 hari terakhir? (50 poin)"
        },
        {
          no: "8.",
          text: "Tidak menggunakan masker saat keluar rumah? (10 poin)"
        }
      ];
      let qSiswa = [
        {
          no: "1.",
          text:
            "Mengalami gejala Flu,Demam dan Batuk dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "2.",
          text: "Mengalami diare dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "3.",
          text:
            "Mengalami kehilangan fungsi indra penciuam, dan indra perasa dalam 7 hari terakhir? (10 poin)"
        },
        {
          no: "4.",
          text:
            "Pernah tidak menggunakan masker saat keluar Asrama, ataupun ketika dalam melaksanakan aktifitas? (10 poin)"
        },
        {
          no: "5.",
          text:
            "Mengalami demam lebih dari 37.2 C dalam 3 hari terakhir? (10 poin)"
        }
      ];
      let createXLSLQGURU = [];
      qGuru.forEach((value, index) => {
        let innerRowData = [];
        innerRowData.push(value["no"]);
        innerRowData.push(value["text"]);
        createXLSLQGURU.push(innerRowData);
      });

      let createXLSLQSISWA = [];
      qSiswa.forEach((value, index) => {
        let innerRowData = [];
        innerRowData.push(value["no"]);
        innerRowData.push(value["text"]);
        createXLSLQSISWA.push(innerRowData);
      });

      filename = filename + ".xlsx";
      let ws_name = sheetname;

      let wb = XLSX.utils.book_new(),
        wsS_SMPPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[0]),
        wsS_SMPPI = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[1]),
        wsS_MA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[2]),
        wsG_MA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[4]),
        wsG_SMPPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[5]),
        wsG_SMPPI = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[6]),
        wsG_STEI = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[7]),
        wsG_mahad = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[8]),
        wsG_SDQ = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[9]),
        wsG_RA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[10]),
        wsG_BPW = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[11]),
        wsG_LDS = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[12]),
        wsG_KY = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[13]),
        wsG_SY = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[14]),
        wsG_HY = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[15]),
        wsG_ADM = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[16]),
        wsG_SDM = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[17]),
        wsG_RT = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[18]),
        wsG_sarpras = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[19]),
        wsG_usaha = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[20]),
        wsG_pengasuhan_pa = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[21]),
        wsG_pengasuhan_pi = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[22]),
        wsG_perguruan = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[23]),
        wsG_pustaka = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[24]),
        wsG_laboran = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[25]),
        wsG_TPA = XLSX.utils.aoa_to_sheet(createXLSLFormatObjALL[26]),
        wsSUM = XLSX.utils.aoa_to_sheet(createXLSLSummaryObj),
        wsQGURU = XLSX.utils.aoa_to_sheet(createXLSLQGURU),
        wsQSISWA = XLSX.utils.aoa_to_sheet(createXLSLQSISWA);
      XLSX.utils.book_append_sheet(wb, wsSUM, " SUMMARY");
      XLSX.utils.book_append_sheet(wb, wsQSISWA, " Pertanyaan SISWA");
      XLSX.utils.book_append_sheet(wb, wsQGURU, " Pertanyaan GURU");
      XLSX.utils.book_append_sheet(wb, wsS_SMPPA, " siswa SMP PUTRA");
      XLSX.utils.book_append_sheet(wb, wsS_SMPPI, " siswi SMP PUTRI");
      XLSX.utils.book_append_sheet(wb, wsS_MA, " siswa MA");
      XLSX.utils.book_append_sheet(wb, wsG_STEI, "STEI");
      XLSX.utils.book_append_sheet(wb, wsG_mahad, "Ma'had Ally");
      XLSX.utils.book_append_sheet(wb, wsG_MA, "MA");
      XLSX.utils.book_append_sheet(wb, wsG_SMPPA, "SMP Putra");
      XLSX.utils.book_append_sheet(wb, wsG_SMPPI, "SMP Putri");
      XLSX.utils.book_append_sheet(wb, wsG_SDQ, "SDQ");
      XLSX.utils.book_append_sheet(wb, wsG_RA, "RA");
      XLSX.utils.book_append_sheet(wb, wsG_BPW, "BPW");
      XLSX.utils.book_append_sheet(wb, wsG_LDS, "LDS");
      XLSX.utils.book_append_sheet(wb, wsG_KY, "Keuangan Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_SY, "Sekertariat Yayaysan");
      XLSX.utils.book_append_sheet(wb, wsG_HY, "HRD Yayasan");
      XLSX.utils.book_append_sheet(wb, wsG_ADM, "ADM & HUMAS");
      XLSX.utils.book_append_sheet(wb, wsG_SDM, "SDM & Penjaminan Mutu");
      XLSX.utils.book_append_sheet(wb, wsG_RT, "RT");
      XLSX.utils.book_append_sheet(wb, wsG_sarpras, "Sarpras");
      XLSX.utils.book_append_sheet(wb, wsG_usaha, "Usaha");
      XLSX.utils.book_append_sheet(wb, wsG_pengasuhan_pa, "Pengasuhan PA");
      XLSX.utils.book_append_sheet(wb, wsG_pengasuhan_pi, "Pengasuhan PI");
      XLSX.utils.book_append_sheet(wb, wsG_perguruan, "Perguruan");
      XLSX.utils.book_append_sheet(wb, wsG_pustaka, "Pustaka");
      XLSX.utils.book_append_sheet(wb, wsG_laboran, "Laboran");
      XLSX.utils.book_append_sheet(wb, wsG_TPA, "TPA");
      XLSX.writeFile(wb, filename);
    },
    async fetchData(order) {
      await this.$http.get("/periode_arsip", {}).then(result => {
        this.optionPeriod = [];
        result.data.forEach(element => {
          var formatedLabel = element.tgl_arsip
            .split("-")
            .reverse()
            .join("-");
          var temp = {
            value: element.tgl_arsip,
            label: formatedLabel
          };
          this.optionPeriod.push(temp);
        });
      });
      if (this.optionPeriod.length == 0) return;
      this.$q.loading.show({ message: "loading data" });
      this.orderBy = "id";
      this.orderType = "DESC";

      var src = "%25";
      if (this.search != "") src = this.search;

      await this.$http
        .get(
          "/arsip_list_survey/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage - 1) * 10 +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.listData = result.data;
          // this.count_page = this.listData.length;
        });
      await this.$http
        .get(
          "/count_arsip_survey/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.count_page = result.data[0].count;
          // this.count_page = this.listData.length;
        });
      await this.$http
        .get(
          "/arsip_list_survey_all/" +
            src +
            "/" +
            this.selectPeriod +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.listData_all = [];
          this.listData_all = result.data;
          this.listData_all.forEach((val, index) => {
            if (val.jabatan == "SISWA") {
              val.Q6 = "-";
              val.Q7 = "-";
              val.Q8 = "-";
            }
          });
        });
      await this.$http.get("/jml_siswa", {}).then(result => {
        this.count_siswa = result.data.count;
      });
      await this.$http
        .get("/jml_survey/" + src + "/" + this.selectPeriod, {})
        .then(result => {
          this.count_survey = result.data.count;
        });
      await this.$http
        .get("/perlu_karantina" + "/" + this.selectPeriod, {})
        .then(result => {
          this.need_karantina = result.data.count;
        });
      await this.$http
        .get("/perlu_isolasi" + "/" + this.selectPeriod, {})
        .then(result => {
          this.need_isolasi = result.data.count;
        });
      await this.$http
        .get("/range_date" + "/" + this.selectPeriod, {})
        .then(result => {
          this.range_date = result.data;
        });

      this.$q.loading.hide();
    },
    arsipkan() {
      this.$http.put("/arsipkan", {}).then(result => {
        //Ga jadi dipake
        // let rand = Math.random().toString(36).substring(6).toUpperCase();
        // this.$http.post('/token/' + rand, {})
        // .then (result => {
        // })

        this.fetchData("nama");
        this.confirm = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  // min-width: 250px;
  flex-grow: 2;
  // background-color: #B9F6CA;
  background-color: #232979;
  color: #babef2;
}
.q-table--dense .q-table tbody tr,
.q-table--dense .q-table tbody td {
  height: 36px;
}
</style>
